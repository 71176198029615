<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i
            class="e6-icon-export_line"
            title="导出"
            @click="handleExport()"
          ></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="itemIdList">
              <input-plus
                v-model="searchForm.itemIdList"
                valueType="Array"
                placeholder="工单ID"
                title="工单ID"
                label="工单ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerName">
              <el-input
                v-model="searchForm.engineerName"
                placeholder="工程师"
                title="工程师"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="recycleStatusList">
              <e6-vr-select
                v-model="searchForm.recycleStatusList"
                :data="RecycleStatusList"
                placeholder="状态"
                title="状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="workTime">
              <e6-date-picker
                label="作业时间"
                ref="workTime"
                type="datetime"
                v-model="searchForm.workTime"
                title="作业时间"
                :picker-options="pickerOptions('searchForm.workTime')"
                start-placeholder="作业时间(始)"
                end-placeholder="作业时间(止)"
                :default-time="['00:00:00', '23:59:59']"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
        >
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'sendBackVoucherUrlList'">
                <el-image
                  v-for="url in row.sendBackVoucherUrlList"
                  :key="url"
                  :src="url"
                  :preview-src-list="row.sendBackVoucherUrlList"
                  style="width:30px;height:30px;"
                >
                </el-image>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { findDownList, getRecycleMaterial, exportRecycleMaterial } from "@/api";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import inputPlus from "@/components/inputPlus";
const nowTime = new Date().getTime(); // 打开页面时的时间戳
export default {
  name: "recoveryEquipReport",
  data() {
    return {
      total: 0,
      timePickerResetList: ["workTime"],
      queryListAPI: getRecycleMaterial,
      searchForm: {
        itemIdList: [],
        queryType: 2,
        sceneName: "",
        engineerName: "",
        recycleStatusList: [],
        workTime: [nowTime - 2592000000], //创建日期
        workTimeStart: nowTime - 2592000000, //创建开始日期 默认一个月
        workTimeEnd: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "itemId",
          fieldLabel: "工单ID",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          fieldLabel: "场景名称",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          fieldLabel: "工程师",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workTime",
          fieldLabel: "作业时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customerName",
          fieldLabel: "客户名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oldMaterialName",
          fieldLabel: "物料名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oldMaterialNo",
          fieldLabel: "物料编码",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "snNumber",
          fieldLabel: "设备号/SN号",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "recycleStatusName",
          fieldLabel: "状态",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "recycleTime",
          fieldLabel: "寄回时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "signTime",
          fieldLabel: "签收时间",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "signPeople",
          fieldLabel: "签收人",
          width: 150,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "expressNo",
          fieldLabel: "快递单号",
          width: 130,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sendBackRemark",
          fieldLabel: "备注",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sendBackVoucherUrlList",
          fieldLabel: "回寄凭证",
          width: 160,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      RecycleStatusList: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: { inputPlus },
  watch: {
    "searchForm.workTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.workTime = this.searchForm.workTime.reverse();
        }
        this.searchForm.workTimeStart = val[0] || "";
        this.searchForm.workTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["RecycleStatus"])];
        let [res] = await Promise.all(promiseList);
        this.RecycleStatusList = this.getFreezeResponse(res, {
          path: "data.RecycleStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportRecycleMaterial);
    }
  }
};
</script>
<style lang="scss" scoped></style>
